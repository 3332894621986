import * as React from 'react';

import { DefaultLayout } from '../layouts/Default';
import { graphql, PageProps } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { ContentBlock } from '../blocks/Content';

interface DataProps {
  pageData: any;
  siteData: any;
}

const DatenschutzPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { content, metadata, opengraph } = pageData;
  const { title, canonicalSiteUrl } = siteData.siteMetadata;

  return (
    <DefaultLayout>
      <ContentBlock data={content} />

      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={title}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default DatenschutzPage;

export const DatenschutzPageQuery = graphql`
  query DatenschutzPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    pageData: cockpitPageDatenschutz {
      content {
        title
        pageID
        teaser
        text
        image {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
