import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

import { PageTitle } from '../../components/PageTitle';

import * as styles from './ContentBlock.module.css';

ContentBlock.propTypes = {};
ContentBlock.defaultProps = {};

export function ContentBlock({ data }) {
  const { title, cssclass, teaser, text, image } = data;

  // TODO: it would be much better if the CMS Adapter would take care
  // of this; but it is not currently supported.
  let modifiedcontent =
    text &&
    text
      .split('src="/')
      .join('src="https://karlwall.cms.mangomedia.at/')
      .split('class="gaOptOut"')
      .join('href="javascript:gaOptout()"');

  return (
    <article className={clsx('defaultWidth', styles.ContentBlock)}>
      <PageTitle>{title}</PageTitle>
      {image && (
        <div>
          <GatsbyImage image={getImage(image)} className={styles.image} />
        </div>
      )}
      {teaser && <div className={styles.teaser} dangerouslySetInnerHTML={{ __html: teaser }} />}
      {modifiedcontent && (
        <div className={clsx(styles.content, cssclass)} dangerouslySetInnerHTML={{ __html: modifiedcontent }} />
      )}
    </article>
  );
}
